import React from 'react';

import { NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';

import css from './WorkStepsSection.module.css';


const WorkStepsSection = props => {

    return (
        <div className={css.root}>
            <p className={css.intro}>
                <FormattedMessage id="LandingPage.WorkStepsSection.intro" />
            </p>
            <h3 className={css.title}>
                <FormattedMessage id="LandingPage.WorkStepsSection.title" />
            </h3>
            <div className={css.stepsContainer}>
                <div className={css.step}>
                    <h3>
                        <div style={{ fontSize: 30 }}>
                            1
                        </div>
                        <div>
                            <FormattedMessage id="LandingPage.WorkStepsSection.step1.title" />
                        </div>
                    </h3>
                    <p>
                        <FormattedMessage id="LandingPage.WorkStepsSection.step1.text" />
                    </p>
                </div>

                <div className={css.step}>
                    <h3>
                        <div style={{ fontSize: 30 }}>
                            2
                        </div>
                        <div>
                            <FormattedMessage id="LandingPage.WorkStepsSection.step2.title" />
                        </div>

                    </h3>
                    <p>
                        <FormattedMessage id="LandingPage.WorkStepsSection.step2.text" />
                    </p>
                </div>
                <div className={css.step}>
                    <h3>
                        <div style={{ fontSize: 30 }}>
                            3
                        </div>
                        <div>
                            <FormattedMessage id="LandingPage.WorkStepsSection.step3.title" />
                        </div>
                    </h3>
                    <p>
                        <FormattedMessage id="LandingPage.WorkStepsSection.step3.text" />
                    </p>
                </div>

            </div>
            <p className={css.outro}>
                <FormattedMessage id="LandingPage.WorkStepsSection.outro" />
            </p>

            <NamedLink name="NewListingPage">
                <PrimaryButton className={css.button}>
                    <FormattedMessage id="LandingPage.WorkStepsSection.button" />
                </PrimaryButton>
            </NamedLink>
        </div>
    );
};


WorkStepsSection.defaultProps = {};
WorkStepsSection.propTypes = {};

export default WorkStepsSection;
