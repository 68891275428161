import React from 'react';

import { NamedLink, PrimaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';

import css from './AboutSection.module.css';


const AboutSection = props => {

    const footerTexts = [1, 2, 3, 4, 5, 6];

    return (
        <div className={css.root}>

            <h3 className={css.title}>
                <FormattedMessage id="LandingPage.AboutSection.title" />
            </h3>

            <div className={css.aboutSection}>
                {footerTexts.map((item, index) => (
                    <p key={index}>
                        <FormattedMessage id={`LandingPage.AboutSection.text${item}`} />
                    </p>
                ))}
            </div>
        </div>
    );
};


AboutSection.defaultProps = {};
AboutSection.propTypes = {};

export default AboutSection;
