import React, { useState, useEffect } from 'react';
import css from './HeroSection.module.css';
import { FormattedMessage } from 'react-intl';

import StarsImage from './images/stars-image.png';

const HeroSection = props => {

    return (
        <div className={css.root}>

            <span className={css.title}>
                <FormattedMessage
                    id="LandingPage.HeroSection.title"
                    values={{ lineBreak: <br /> }}
                />
            </span>

            <div className={css.titleHighlight}>
                <span>
                    <FormattedMessage id="LandingPage.HeroSection.title2" />
                </span>
                <img className={css.stars} src={StarsImage} />
            </div>

            <p className={css.info}>
                "<FormattedMessage id="LandingPage.HeroSection.info" />"
            </p>
        </div>
    );
};


HeroSection.defaultProps = {};
HeroSection.propTypes = {};

export default HeroSection;
