import React, { useState, useEffect } from 'react';
import css from './CarSelectorSection.module.css';
import { FormattedMessage } from 'react-intl';
import { NamedLink, PrimaryButton } from '../../../components';
import { NamedLinkComponent } from '../../../components/NamedLink/NamedLink';
import { carModels } from '../../../util/carModels';

const CarSelectorSection = props => {
    const { listingFieldsConfig } = props;

    const brands = listingFieldsConfig.find(f => f.key === 'brand')?.enumOptions;

    const [makes, setMakes] = useState(brands);
    const [selectedMake, setSelectedMake] = useState('');
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState('');
    const [years, setYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState('');

    useEffect(() => {
        if (selectedMake) {
            setModels(carModels.find(c => c.option === selectedMake).models);
        } else {
            setModels([]);
        }
        setSelectedModel('');
    }, [selectedMake]);

    useEffect(() => {
        if (selectedModel) {
            const currentYear = new Date().getFullYear();
            setYears(Array.from({ length: 50 }, (_, index) => currentYear - index));
        } else {
            setYears([]);
        }
        setSelectedYear('');
    }, [selectedModel]);

    const handleSubmit = () => {
        const queryParams = new URLSearchParams({
            brand: selectedMake,
            model: selectedModel,
            year: selectedYear,
        });

        // Assuming the base URL is something like 'http://example.com/cars'
        // const url = `http://example.com/cars?${queryParams.toString()}`;
        // console.log(url); // You can replace this with actual navigation if necessary.
    };

    return (

        <div className={css.root}>
            <p className={css.title}>
                <FormattedMessage id="LandingPage.CarSelectorSection.justSell" />
            </p>

            <div className={css.carDetailsContainer}>
                <div>
                    <label className={css.carDetailsLabel}>
                        <FormattedMessage id="LandingPage.CarSelectorSection.brand" />
                    </label>
                    <select className={css.dropdown} value={selectedMake} onChange={e => setSelectedMake(e.target.value)}>
                        <option value="">Wählen Sie eine Marke</option>
                        {makes.map(make => (
                            <option key={make.option} value={make.option}>
                                {make.label}
                            </option>
                        ))}
                    </select>

                </div>

                <div>
                    <label className={css.carDetailsLabel}>
                        <FormattedMessage id="LandingPage.CarSelectorSection.model" />
                    </label>
                    <select className={css.dropdown} value={selectedModel} onChange={e => setSelectedModel(e.target.value)} disabled={!selectedMake}>
                        <option value="">Wählen Sie ein Modell</option>
                        {selectedMake && <option value="all">Tous</option>}
                        {models.map(model => (
                            <option key={model} value={model}>
                                {model}
                            </option>
                        ))}
                    </select>
                </div>

                <div>
                    <label className={css.carDetailsLabel}>
                        <FormattedMessage id="LandingPage.CarSelectorSection.year" />
                    </label>
                    <select className={css.dropdown} value={selectedYear} onChange={e => setSelectedYear(e.target.value)} disabled={!selectedModel}>
                        <option value="">Wählen Sie ein Jahr</option>
                        {years.map(year => (
                            <option key={year} value={year}>
                                {year}
                            </option>
                        ))}
                    </select>
                </div>

                {selectedMake ?
                    <NamedLink
                        name="EditListingPageFromLanding"
                        params={{
                            id: '00000000-0000-0000-0000-000000000000', slug: 'draft', type: 'new', tab: 'details',
                            brand: selectedMake || ' ', model: selectedModel || ' ', year: selectedYear || ' '
                        }}>
                        <PrimaryButton className={css.button}
                        >
                            <FormattedMessage id="LandingPage.CarSelectorSection.continue" />
                        </PrimaryButton>
                    </NamedLink> :
                    <NamedLink
                        name="NewListingPage"
                    >
                        <PrimaryButton className={css.button}
                        >
                            <FormattedMessage id="LandingPage.CarSelectorSection.continue" />
                        </PrimaryButton>
                    </NamedLink>
                }
            </div>
        </div>
    );
};


CarSelectorSection.defaultProps = {};
CarSelectorSection.propTypes = {};

export default CarSelectorSection;
