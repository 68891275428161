import React, { useEffect } from 'react';

import { NamedLink, SecondaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';
import ArrowIcon from './images/ArrowIcon';
import dynamic from 'next/dynamic';
import './swiper.css';

import css from './SliderSection.module.css';
import Image1 from './images/1.jpeg';
import Image2 from './images/2.jpeg';
import Image3 from './images/3.jpeg';
import Image4 from './images/4.jpeg';


const ArrowBack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z" clipRule="evenodd" />
        </svg>
    )
}

const ArrowForward = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path fillRule="evenodd" d="M16.28 11.47a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 0 1-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 0 1 1.06-1.06l7.5 7.5Z" clipRule="evenodd" />
        </svg>
    )
}

const Swiper =
    typeof window !== 'undefined'
        ? require('swiper/react').Swiper
        : dynamic(() => import('swiper/react'), {
            ssr: false,
        });
const SwiperSlide =
    typeof window !== 'undefined'
        ? require('swiper/react').SwiperSlide
        : dynamic(() => import('swiper/react'), {
            ssr: false,
        });
const Navigation =
    typeof window !== 'undefined'
        ? require('swiper').Navigation
        : dynamic(() => import('swiper'), {
            ssr: false,
        });

const SliderSection = () => {
    useEffect(() => {
        // Load swiper CSS only in the browser
        if (typeof window !== 'undefined') {
            require('swiper/css');
            require('swiper/css/bundle');
            require('swiper/css/navigation');
        }
    }, []);


    const renderSlides = () => {
        const slides = [
            <SwiperSlide key={1}>
                <div className={css.card} >
                    <img src={Image1} className={css.reviewerImage} />
                    <div className={css.review}>
                        Very easy to find the right car. Easy to use car selling platform.
                    </div>
                </div>
            </SwiperSlide>,

            <SwiperSlide key={2}>
                <div className={css.card} >
                    <img src={Image2} className={css.reviewerImage} />
                    <div className={css.review}>
                        Reserved the car quicky so I was sure 100% I'll have it.
                    </div>
                </div>
            </SwiperSlide>,

            <SwiperSlide key={3}>
                <div className={css.card} >
                    <img src={Image3} className={css.reviewerImage} />
                    <div className={css.review}>
                        Payment secure with fast reservation.
                    </div>
                </div>
            </SwiperSlide>,

            <SwiperSlide key={4}>
                <div className={css.card} >
                    <img src={Image4} className={css.reviewerImage} />
                    <div className={css.review}>
                        Reserved the car quicky so I was sure 100% I'll have it.
                    </div>
                </div>
            </SwiperSlide>,

        ]
        return slides;
    };

    const slides = [1, 2, 3, 4, 5, 6];
    const isMobile = typeof window !== 'undefined' && window.innerWidth < 768

    return (
        <div className={css.root}>
            <h3 className={css.title}>
                <FormattedMessage id="LandingPage.SliderSection.title" />
            </h3>

            <div className={css.sliderContainer}>
                <div className="custom_prev">
                    <ArrowBack />
                </div>
                <Swiper
                    className={css.swiper}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.custom_next',
                        prevEl: '.custom_prev',
                    }}
                    loop={true}
                    spaceBetween={40}
                    slidesPerView={isMobile ? 1 : 3}
                >
                    {/* {slides.map((index) => (
                        <SwiperSlide key={index}>
                            <div className={css.dummyBlackCard} />
                        </SwiperSlide>
                    ))} */}
                    {renderSlides()}
                </Swiper>
                <div className="custom_next">
                    <ArrowForward />
                </div>
            </div>

            <NamedLink name="SearchPage">
                <SecondaryButton className={css.button}>
                    <FormattedMessage id="LandingPage.SliderSection.button" />
                    <ArrowIcon />
                </SecondaryButton>
            </NamedLink>
        </div>
    );
};

export default SliderSection;
