import React from 'react';

import { NamedLink, SecondaryButton } from '../../../components';
import { FormattedMessage } from 'react-intl';
import CheckIcon from './images/CheckIcon.png';

import css from './AdvantagesSection.module.css';

const advantagesList = [1, 2, 3, 4, 5, 6];



const AdvantagesSection = props => {

    return (
        <div className={css.root}>
            <div>
                <h3 className={css.title}>
                    <FormattedMessage id="LandingPage.AdvantagesSection.title" />
                </h3>
                <div className={css.columnList}>

                    {advantagesList.map((item, index) => (
                        <div className={css.listItem} key={index}>
                            <img src={CheckIcon} className={css.checkIcon} />
                            <span><FormattedMessage id={`LandingPage.AdvantagesSection.item${item}`} /></span>
                        </div>
                    ))}
                </div>
            </div>

            <NamedLink name="NewListingPage">
                <SecondaryButton className={css.button}>
                    <FormattedMessage id="LandingPage.AdvantagesSection.button" />
                </SecondaryButton>
            </NamedLink>
        </div>
    );
};


AdvantagesSection.defaultProps = {};
AdvantagesSection.propTypes = {};

export default AdvantagesSection;
