import React from 'react';
import css from './VideoSection.module.css';
import { BlockDefault } from '../../PageBuilder/BlockBuilder';



const VideoSection = props => {


    return (
        <div className={css.root}>
            <div className={css.container}>
                <iframe
                    width="100%"
                    height={400}
                    // src="https://youtube.com/embed/ruRD6rUzcqo"
                    src="https://youtube.com/embed/ruRD6rUzcqo?feature=share"
                    title="youtube video 1"
                    frameBorder={0}
                    allowFullScreen
                />

                <iframe
                    width="100%"
                    height={400}
                    // src="https://youtube.com/embed/9FSLcjVCHKs"
                    src="https://youtube.com/embed/9FSLcjVCHKs?feature=share"
                    title="youtube video 2"
                    frameBorder={0}
                    allowFullScreen
                />
                <iframe
                    width="100%"
                    height={400}
                    src="https://youtube.com/embed/YDboZQ9BVmo?feature=share"
                    title="youtube video 3"
                    frameBorder={0}
                    allowFullScreen
                />
            </div>
        </div>
    );
};

VideoSection.defaultProps = {};
VideoSection.propTypes = {};

export default VideoSection;
