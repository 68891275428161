import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import CarSelectorSection from './CarSelectorSection/CarSelectorSection';
import SoldCarsSection from './SoldCarsSection/SoldCarsSection';
import HeroSection from './HeroSection/HeroSection';
import WorkStepsSection from './WorkStepsSection/WorkStepsSection';
import SliderSection from './SliderSection/SliderSection';
import AdvantagesSection from './AdvantagesSection/AdvantagesSection';
import VideoSection from './VideoSection/VideoSection';
import { useConfiguration } from '../../context/configurationContext';
import AboutSection from './AboutSection/AboutSection';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);


export const LandingPageComponent = props => {
  const { pageAssetsData, inProgress, error } = props;
  const config = useConfiguration();
  const listingFieldsConfig = config.listing.listingFields;
  return (
    <PageBuilder
      // pageAssetsData={pageAssetsData?.[camelize(ASSET_NAME)]?.data}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
      addedComponentsMaybe={
        <div>
          <HeroSection />
          <CarSelectorSection listingFieldsConfig={listingFieldsConfig} />
          <WorkStepsSection />
          {/* <SliderSection /> */}
          <VideoSection />
          <AdvantagesSection />
          <SoldCarsSection />
          <AboutSection />
        </div>
      }
    />
  );
};

LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  return { pageAssetsData, inProgress, error };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
