import React, { useState, useEffect } from 'react';
import css from './SoldCarsSection.module.css';

import Image1 from './images/1.jpg';
import Image2 from './images/2.jpg';
import Image3 from './images/3.jpg';
import Image4 from './images/4.jpg';
import Image5 from './images/5.jpg';
import Image6 from './images/6.jpg';
import Image7 from './images/7.jpg';
import Image8 from './images/8.jpg';
import Image9 from './images/9.jpg';
import { FormattedDate, FormattedMessage } from 'react-intl';

const soldCarsData = [
    { name: "BMW ?", image: Image1, price: 'CHF 3200' },
    { name: "BMW ?", image: Image2, price: 'CHF 14000 ' },
    { name: "Peugeot?", image: Image3, price: 'CHF 4000 ' },
    { name: "???", image: Image4, price: 'CHF 1700 ' },
    { name: "???", image: Image5, price: 'CHF 43000 ' },
    { name: "???", image: Image6, price: 'CHF 99000 ' },
    { name: "???", image: Image7, price: 'CHF 18000 ' },
    { name: "???", image: Image8, price: 'CHF 74000 ' },
    { name: "???", image: Image9, price: 'CHF 5600 ' },
];

const SoldCarsSection = props => {

    const soldCars = soldCarsData;

    return (
        <div className={css.root}>

            <h3 className={css.title}>
                <FormattedMessage id="LandingPage.CarSelectorSection.title" />
            </h3>
            <div className={css.carsContainer}>
                {soldCars.map((car, index) => (
                    <div key={index} className={css.carContainer}>
                        <img className={css.img} src={car.image} />
                        <label className={css.soldLabel}>
                            <FormattedMessage id="LandingPage.CarSelectorSection.sold" />
                        </label>

                        {/* <p className={css.carName}>
                            {car.name}
                        </p>

                        <p className={css.carPrice}>
                            {car.price}
                        </p> */}
                    </div>
                ))}
            </div>
        </div>
    );
};


SoldCarsSection.defaultProps = {};
SoldCarsSection.propTypes = {};

export default SoldCarsSection;
